import React from "react"

import NavMenu from "./NavMenu"
import { NavItem } from "./NavItem"
import { NavItemProps } from "../../ts/interfaces"
import { useAuthState } from "../../contexts/authContext"

const UserMenu = ({
  scrolled,
  navItems,
  hideMobile,
}: {
  scrolled: boolean
  navItems: NavItemProps[]
  hideMobile?: boolean
}) => {
  const state = useAuthState()
  const { firstName, token } = state

  // if (!token || !state.isLoggedIn()) {
  //   return (
  //     <NavItem scrolled={scrolled} hideMobile={hideMobile}>
  //       <Link data-test-id="main-nav-login" to="/login">
  //         Login
  //       </Link>
  //     </NavItem>
  //   )
  // }

  return (
    <NavItem scrolled={scrolled} hideMobile={hideMobile}>
      <NavMenu
        title={firstName || "My Account"}
        keyId="USER"
        items={navItems}
      />
    </NavItem>
  )
}

export default UserMenu
